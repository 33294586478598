@keyframes loading {
  0% {
    left: -5%;
    width: 5%;
  }
  10% {
    width: 5%;
  }
  30% {
    width: 50%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.loading-bar {
  display: none;
}

.loading-bar > .spinner {
  display: none;
}

.loading > .loading-bar {
  display: block;
}

.loading > .loading-bar > .spinner {
  display: block;
  animation: loading 0.5s cubic-bezier(0.54, 0.98, 1, 1) infinite;
}
